import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav style={navbarStyle}>
      <div style={logoStyle}>Logo</div>
      <ul style={navLinksStyle}>
        <li><Link to="/" style={linkStyle}>Home</Link></li>
        <li><Link to="/c" style={linkStyle}>C</Link></li>
        <li><Link to="/cpp" style={linkStyle}>C++</Link></li>
        <li><Link to="/python" style={linkStyle}>Python</Link></li>
        <li><Link to="/java" style={linkStyle}>Java</Link></li>
        <li><Link to="/html" style={linkStyle}>HTML</Link></li>
        <li><Link to="/css" style={linkStyle}>CSS</Link></li>
        <li><Link to="/sql" style={linkStyle}>SQL</Link></li>
      </ul>
    </nav>
  );
};

// Styles
const navbarStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  backgroundColor: '#58368F',
  color: 'white',
  flexWrap: 'wrap'
};

const logoStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
};

const navLinksStyle = {
  display: 'flex',
  listStyle: 'none',
  gap: '15px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: 0,
  padding: 0
};

const linkStyle = {
  textDecoration: 'none',
  color: 'white',
  fontSize: '18px',
  transition: 'color 0.2s',
};

linkStyle[':hover'] = {
  color: '#935CEB',
};

export default Navbar;
