import React from 'react';

const CPage = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>C Programming</h1>
      <p>This is the page for the C programming language.</p>
    </div>
  );
};

export default CPage;
