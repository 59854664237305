import React from 'react';

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>&copy; 2024 Your Website</p>
      <ul style={footerLinksStyle}>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/terms">Terms of Service</a></li>
        <li><a href="/contact">Contact Us</a></li>
      </ul>
    </footer>
  );
};

// Styles
const footerStyle = {
  backgroundColor: '#58368F',
  color: 'white',
  textAlign: 'center',
  padding: '20px 0',
  marginTop: 'auto'
};

const footerLinksStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '15px',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  flexWrap: 'wrap'
};

export default Footer;
