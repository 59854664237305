import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div style={containerStyle}>
      <h1>Welcome to Programming Courses</h1>
      <div style={buttonContainerStyle}>
        <Link to="/c" style={buttonStyle}>C</Link>
        <Link to="/cpp" style={buttonStyle}>C++</Link>
        <Link to="/python" style={buttonStyle}>Python</Link>
        <Link to="/java" style={buttonStyle}>Java</Link>
        <Link to="/html" style={buttonStyle}>HTML</Link>
        <Link to="/css" style={buttonStyle}>CSS</Link>
        <Link to="/sql" style={buttonStyle}>SQL</Link>
      </div>
      <h2>Interview Questions</h2>
      <div style={buttonContainerStyle}>
        <Link to="/c-interview" style={buttonStyle}>C Interview Questions</Link>
        <Link to="/java-interview" style={buttonStyle}>Java Interview Questions</Link>
        <Link to="/python-interview" style={buttonStyle}>Python Interview Questions</Link>
        <Link to="/html-interview" style={buttonStyle}>HTML Interview Questions</Link>
        <Link to="/css-interview" style={buttonStyle}>CSS Interview Questions</Link>
        <Link to="/sql-interview" style={buttonStyle}>SQL Interview Questions</Link>
      </div>
      <h2>Programming Questions</h2>
      <div style={buttonContainerStyle}>
        <Link to="/c-interview" style={buttonStyle}>C c programming Questions</Link>
        <Link to="/java-interview" style={buttonStyle}>Java programming Questions</Link>
        <Link to="/python-interview" style={buttonStyle}>Python programming Questions</Link>
        <Link to="/html-interview" style={buttonStyle}>HTML programming Questions</Link>
        <Link to="/css-interview" style={buttonStyle}>CSS programming Questions</Link>
        <Link to="/sql-interview" style={buttonStyle}>SQL programming Questions</Link>
      </div>
    </div>
  );
};

// Styles for the page
const containerStyle = {
  textAlign: 'center',
  padding: '20px',
};

const buttonContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '20px',
  margin: '20px 0',
};

const buttonStyle = {
  padding: '20px 40px',
  fontSize: '18px',
  backgroundColor: '#58368F',
  color: 'white',
  textDecoration: 'none',
  borderRadius: '10px',
  transition: 'background-color 0.3s',
  textAlign: 'center',
  width: '200px',
};

buttonStyle[':hover'] = {
  backgroundColor: '#935CEB',
};

export default Home;
