import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import C from './Pages/CPage';
import Cpp from './Pages/CPlusPlusPage';
import Python from './Pages/PythonPage';
import Java from './Pages/JavaPage';
import HTML from './Pages/HtmlPage';
import CSS from './Pages/CssPage';
import SQL from './Pages/SqlPage';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/c" element={<C />} />
        <Route path="/cpp" element={<Cpp />} />
        <Route path="/python" element={<Python />} />
        <Route path="/java" element={<Java />} />
        <Route path="/html" element={<HTML />} />
        <Route path="/css" element={<CSS />} />
        <Route path="/sql" element={<SQL />} />
      </Routes>
      <Footer />
    </Router>
  );
};

const appStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

const contentStyle = {
  flex: '1', // This makes sure the content takes up the remaining space, pushing the footer to the bottom
};

export default App;
